<template>
	<div class="loginbg">

		<div class="login_box">
			<div style="text-align: center; padding-bottom: 20px; font-size: 24px; font-weight: bold;">欢迎登录安学保</div>
			<div class="coricon" @click="changeLoginType">
				<img v-if="!showQrcode" :src="'./images/icon-qrcode.png'" alt="">
				<img v-else :src="'./images/icon-pwd.png'" alt="">
			</div>
			<div v-if="!showQrcode">


				<el-form :model="EditItem">

					<el-form-item>

						<el-input placeholder="请输入手机号码" @keyup.enter.native="login" v-model="username">
							<template slot="prepend">手机号码</template>
						</el-input>

					</el-form-item>
					<el-form-item>
						<div style="position:relative;">
							<el-input placeholder="密码" @keyup.enter.native="login" v-model="password" type="password">
								<template slot="prepend">登录密码</template>
							</el-input>


							<!-- <el-input placeholder="短信验证码" @keyup.enter.native="login" v-model="password">
								<template slot="prepend">短信验证</template>
							</el-input>
							<div style="position:absolute;top:0;right:10px">
								<el-button size="mini" style="width:100px" @click.stop="sendYzm">
									{{ second == 120 ? '发送验证码' : second + 'S后重发' }}
								</el-button>
							</div> -->
						</div>


					</el-form-item>

					<el-form-item>
						<div style="position:relative;">
							<el-input placeholder="验证码" @keyup.enter.native="login" v-model="yzm" type="">
								<template slot="prepend">&nbsp;验&nbsp;证&nbsp;码&nbsp;</template>
							</el-input>



							<div @click="changeYzm"
								style=" position: absolute; right: 0; top: 0; border: 0; transform: scale(0.7,0.7); transform-origin: right top;"
								v-html="yzmUrl">
							</div>
						</div>


					</el-form-item>

					<div style="height: 20px; text-align: center; padding-top: 50px;">
						<el-button type="primary" style="width: 100%;" @click="login">登录</el-button>
					</div>
				</el-form>

			</div>

			<div v-else>
				<div style="width: 60%; margin: 0 auto; padding-top: 30px;">
					<img :src="qrcodeUrl ? qrcodeUrl : '../../images/qrcode.jpg'" alt="" style="width: 100%;">
				</div>

			</div>



		</div>




	</div>
</template>

<script>
export default {
	data() {
		return {
			isShowModal: false,
			showbtn: false,
			showVideo: true,
			bgidx: "",
			bgvideourl: "",
			EditItem: {},
			username: "",
			password: "",
			session_id: "",
			yzmUrl: "",
			second: 120,
			yzmTimer: 0,
			yzmTaped: false,
			yzm: "",

			showQrcode: false,
			qrcodeUrl: "",
			qrcodeChecking: false,
			qrcodeTimer: -1,
			pwdForm: {
				newPwd1: "",
				newPwd2: "",

			}
		}
	},
	mounted() {



		this.getYZM()

	},
	methods: {


		sendYzm() {

			if (!this.username.trim()) {
				this.$message.error('请输入手机号');
				return
			} else {
				var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if (!myreg.test(this.username.trim())) {
					this.$message.error('请输入正确的手机号');
					return
				}
			}



			if (this.yzmTaped) {
				return
			} else {
				this.yzmTaped = 1
			}


			this.$message.success("短信验证码发送中...")
			this.$http.post("/api/send_adminplogin_sms", { phone: this.username }).then(res => {
				let second = this.second;
				this.$message.success("短信发送成功")
				let t = setInterval(() => {
					second--
					if (second == -1) {
						second = 120
					}
					this.second = second
					if (second == 120) {
						clearInterval(this.yzmTimer)
						this.yzmTimer = 0
						this.yzmTaped = 0
					}
				}, 1000);
				this.yzmTimer = t
			})

		},


		getQrcode() {
			this.$http.post("/api/login_qrcode", {
				pmid: window.pmid
			}).then(res => {

				this.session_id = res.data.session;

				this.qrcodeUrl = res.data.url;
				if (!this.qrcodeChecking) {
					this.qrcodeChecking = true

					this.checkQrcode();
				}
			})



		},
		checkQrcode() {

			this.qrcodeTimer = setInterval(() => {

				this.$http.post("/api/check_qrcode", {
					session_id: this.session_id,
					noloading: 1,
					pmid: window.pmid
				}).then(res => {
					if (res.data.token) {
						if (res.data.pmid != window.pmid) {
							this.$message({
								message: "错误的二维码",
								type: 'error',
								offset: '600'
							});
						} else {
							let menus = res.data.menus;
							delete res.data.menus
							sessionStorage.setItem("userinfo", JSON.stringify(res.data))
							if (!localStorage.getItem("year")) {
								let month = new Date().getMonth() + 1
								if (month == 12) {
									localStorage.setItem("year", new Date().getFullYear() + 1)
								} else {
									localStorage.setItem("year", new Date().getFullYear())
								}

							}
							sessionStorage.setItem("menus", menus)
							this.getMenus()
							clearInterval(this.qrcodeTimer)
							this.$router.push("/home")
						}

					}
				})
			}, 1000)
		},
		getYZM() {
			this.$http.post("/api/get_login_randomcode", {
				pmid: window.pmid
			}).then(res => {
				this.session_id = res.data.data.session_id;
				this.yzmUrl = res.data.data.svg;
			})


		},
		changeYzm() {
			this.getYZM()
		},
		login() {
			if (this.username.trim() && this.password.trim()) {//&& this.yzm.trim()
				this.$http.post("/api/admin_login", {
					loginname: this.username,
					mm: this.password,
					yzm: this.yzm.toLowerCase(),
					session_id: this.session_id,
					pmid: window.pmid,
					day: 7//7天后过期
				}).then(res => {
					if (res.data && res.data.auth) {

						localStorage.setItem("auth", res.data.auth)
						clearInterval(this.qrcodeTimer)

						this.$http.post("/api/getUserMenu").then(res => {
							console.log(res)
							if (res.data[0].children.length > 0) {
								this.$router.push(res.data[0].children[0].path)
							} else {
								this.$router.push(res.data[0].path)
							}

						})


						// if (!res.data.upd_pwd) {
						// 	this.isShowModal = true
						// } else {
						// 	this.$router.push("/home")
						// }

					} else {
						this.$message({
							message: res.data.msg,
							type: 'error',
							offset: '600'
						});

					}


				}, err => {

				})
			} else {
				this.$message({
					message: "好像没有填完整~",
					type: 'error',
					offset: '600'
				});
			}
		},
		changeLoginType() {
			this.showQrcode = !this.showQrcode
			if (this.showQrcode && !this.qrcodeUrl) {
				this.getQrcode()
			}
		},

		checkPassWord(value) {
			if (value.length < 8 || value.length > 16) { //最初级别
				this.$message.error("密码长度为8-16位")
				return false;
			}
			if (!/\d/.test(value)) { //如果用户输入的密码 包含了数字
				this.$message.error("密码必须包含数字")
				return false;
			}
			if (!/[a-z]/.test(value) && !/[A-Z]/.test(value)) { //如果用户输入的密码 包含了小写的a到z
				this.$message.error("密码必须包含大小写字母")
				return false;
			}

			var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
				regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

			if (!regEn.test(value) && !regCn.test(value)) {
				this.$message.error("密码必须包含特殊字符")
				return false;
			}

			return true


		},

		savePwd() {

			if (!this.pwdForm.newPwd1) {
				this.$message.error("请输入新密码")
				return
			}
			if (!this.pwdForm.newPwd2) {
				this.$message.error("请再次输入新密码")
				return
			}
			if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
				this.$message.error("两次密码不一致")
				return
			}
			if (this.checkPassWord(this.pwdForm.newPwd2)) {
				this.showbtn = true;
				this.$http.post("/api/reset_myself_pwd_first", this.pwdForm).then(ok => {
					if (ok.data) {
						this.$message.success("修改成功,即将跳转")
						setTimeout(() => {
							this.$router.push("/home")
						}, 3000)

					}
				})
			}


		},
	}
}
</script>

<style scoped>
.loginbg {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-image: url(http://desk-fd.zol-img.com.cn/t_s1920x1080/g2/M00/0A/0F/ChMlWl7N03WIIL59ABIypJVaTEMAAPg7wJlY6oAEjK8712.jpg);
}

.login_box {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -180px;
	margin-top: -270px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	background-color: #fff;
	width: 320px;
	height: 340px;
	padding: 20px;
	box-shadow: 0 2px 10px #999;
	-moz-box-shadow: #999 0 2px 10px;
	-webkit-box-shadow: #999 0 2px 10px;
}

.coricon {
	position: absolute;
	top: 0;
	right: 0;
	;
	width: 60px;
	height: 60px;
}

.coricon img {
	width: 100%;
	transform: scale(0.5, 0.5);
}

.bgvideo {
	position: fixed;
	width: 100%;
	height: 100%;
	top: -10;
	left: 0;
	z-index: -100;

}

.bgvideo video {
	width: 100%;
	/*加滤镜*/
	filter: blur(0px);

}
</style>
