import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/login',
	name: 'Login',
	component: Login
},
{
	path: '*',
	redirect: "/home"
}, 
{
	path: '/index',
	name: 'index',
	component: index,
	children: [

		{
			path: '/home',
			component: resolve => require(['../views/home/home'], resolve)
		},
		{
			path: '/sys_menu',
			component: resolve => require(['../views/sys/sys_menu'], resolve)
		}, {
			path: '/sys_role',
			component: resolve => require(['../views/sys/sys_role'], resolve)
		},
		{
			path: '/sys_teacherDept',
			component: resolve => require(['../views/sys/sys_teacherDept'], resolve)
		},
		{
			path: '/init',
			component: resolve => require(['../views/sys/theme'], resolve)
		},{
			path: '/banner',
			component: resolve => require(['../views/menu/banner'], resolve)
		},
		
		{
			path: '/teacher',
			component: resolve => require(['../views/sys/teacher'], resolve)
		},
		
		// {
		// 	path: '/student',
		// 	component: resolve => require(['../views/sys/student'], resolve)
		// }, 
		 {
			path: '/notify',
			component: resolve => require(['../views/notify/list'], resolve)
		}, {
			path: '/notifyedit',
			component: resolve => require(['../views/notify/articleedit'], resolve)
		},
		{
			path: '/orders',
			component: resolve => require(['../views/orders/orders'], resolve)
		},{
			path: '/lipei',
			component: resolve => require(['../views/orders/lipei'], resolve)
		},{
			path: '/refund',
			component: resolve => require(['../views/orders/refund'], resolve)
		},{
			path: '/qanda',
			component: resolve => require(['../views/QAndA/adviceList'], resolve)
		},{
			path: '/bindapply',
			component: resolve => require(['../views/orders/bindapply'], resolve)
		},{
			path: '/slist',
			component: resolve => require(['../views/shop/slist'], resolve)
		},{
			path: '/proedit/:id',
			component: resolve => require(['../views/shop/proedit'], resolve)
		},{
			path: '/shoporders',
			component: resolve => require(['../views/shop/orders'], resolve)
		},{
			path: '/qrcode',
			component: resolve => require(['../views/sys/qrcode'], resolve)
		},

		
		
	]
},
{
	path: '/pingzheng',
	component: resolve => require(['../views/h5/pingzheng'], resolve)
}, 

]



const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
})


//获取url参数的方法，支持vue路由各种参数写法
const $request = function (name) {
	var params = [];
	var value = null;
	if (window.location.href.indexOf(name + "=") >= 0) {
		let q = window.location.href.split("?")[1];
		if (q.indexOf("#") > 0) {
			q = q.split("#")[0];
		}
		params = q.split("&");
		for (let p of params) {
			if (p.indexOf(name + "=") >= 0) {
				value = p.replace(name + "=", "").replace(/\//g, "")
				break;
			}
		}
	}
	if (value && value != undefined && value != "undefined") {
		return value
	} else {
		return "";
	}
}


//路由进入之前检查是否登录
router.beforeEach((to, from, next) => {
	let auth = $request("auth"); //检查url是否带有登录字符串
	if (auth) {
		window.localStorage.setItem("auth", auth); //将登录字符串保存到缓存
		//移除url的auth并刷新页面
		let localUrl = window.location.href.replace(auth, '').replace("?auth=", '').replace("&auth=", '')
		window.location.href = localUrl
		window.location.reload()
	} else { //跳转统一登录
		if (window.localStorage.getItem("auth")) { //检查本地是否登录
			next()
		} else {
			if (to.path == "/login") {
				next()
			} else {
				next({ path: "/login" })
			}
		}
	}
})



export default router
